<template>
  <va-navbar color="#ffffff" class="mb-4 pa-0">
    <template #center>
      <va-navbar-item>SIGN IN</va-navbar-item>
    </template>
    <template #right>
      <va-navbar-item>
        <div v-if="showLoading == false" class="mr-4" />

        <va-progress-circle
          v-else
          indeterminate
          size="3rem"
          :thickness="0.2"
          color="danger"
        ></va-progress-circle>
      </va-navbar-item>
    </template>
  </va-navbar>
  <div class="center">
    <!-- <div class="row">
      <div class="flex md6 lg4"> -->
    <va-card stripe stripe-color="success">
      <va-card-title>Title</va-card-title>
      <va-card-content>
        <va-form
          ref="loginForm"
          @validation="validation = $event"
          @submit.prevent="submitForm"
        >
          <!-- <div style="min-width: 500px">
      <div class="flex row">
        <div class="flex"> -->
          <!-- <div class="row flex">
      <div> -->
          <va-input
            label="Email"
            class="mb-4 mx-2"
            v-model="userEmail"
            :rules="emailRules"
          ></va-input>
          <va-input
            label="Password"
            type="password"
            class="mb-4 mx-2"
            v-model="password"
            :rules="nameRules"
          ></va-input>
          <va-button class="mb-2 mx-2" color="success" @click="submitForm">
            Sign In
          </va-button>
          <!-- </div>
    </div> -->
          <!-- </div>
      </div>
    </div> -->
        </va-form>
      </va-card-content>
    </va-card>
    <!-- </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { authStore } from "@/store/auth_store";
import SignInDataService from "../services/siginin-data-service";
export default {
  data() {
    return {
      userEmail: "",
      password: "",
      showLoading: false,
      nameRules: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length > 1) || "Name must be atleast 1 characters long",
      ],
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },
  methods: {
    ...mapActions(authStore, ["updateToken"]),
    formatDate(date) {
      return `${utility.monthNames[date.getMonth()]} - ${date.getFullYear()}`;
    },
    dismissDialog() {
      this.$emit("closeDialog");
    },
    submitForm() {
      this.$refs.loginForm.validate();
      console.log("validation is ", this.validation);
      if (this.validation != null && this.validation != false) {
        this.showLoading = true;
        this.signInToServer();
      } else {
        this.$vaToast.init({
          message: "Please fill the required fields.",
          color: "danger",
        });
      }
    },
    clear() {
      this.resetAllData();
    },
    resetAllData() {
      this.validation = null;
      this.userEmail = "";
      this.password = "";
      this.showLoading = false;
    },
    async signInToServer() {
      const usersJson = {
        email: this.userEmail,
        password: this.password,
      };

      try {
        const usersResponse = await SignInDataService.signInUser(usersJson);
        if (usersResponse.status == 200 && usersResponse.data != null) {
          const accessToken = usersResponse.data.access_token;
          console.log("Access Token ", accessToken);

          if (accessToken != null) {
            this.resetAllData();
            //this calls the pinia store
            this.updateToken(accessToken);
            this.$router.push("/baseLayout");
          }
        } else {
          this.showLoading = false;

          this.$vaToast.init({
            message: "Failed to upload contact. Please try again.",
            color: "danger",
          });
        }
      } catch (error) {
        this.showLoading = false;
        console.log(error);
        this.$vaToast.init({
          message:
            "Failed to upload contact. Please check all your fields and try again.",
          color: "danger",
        });
      }
    },
  },
};
</script>

<style scoped>
.center {
  margin: auto;
  width: 40%;
  padding: 10px;
}
</style>