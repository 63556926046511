<template>
  <base-header-view title="Priests"></base-header-view>
  <va-card class="ma-4">
    <va-card-content>
      <div class="mr-2 mt-2 d-flex flex-row-reverse">
        <va-button color="success" @click="toggleDialog(true)">
          Add Priest +
        </va-button>
      </div>
      <!-- Show Add Priests Page -->
      <va-modal
        v-model="isDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <div class="ma-0 pa-0"></div>
        <add-priest-page @closeDialog="toggleDialog(false)"> </add-priest-page>
      </va-modal>

      <va-modal
        v-model="isEditPriestDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <edit-priest-page
          :selectedPriest="selectedPriest"
          @closeDialog="toggleEditPriestDialog(false)"
        ></edit-priest-page>
      </va-modal>

      <va-modal
        v-model="showDeleteAlert"
        message="Do you want to delete this priest ? "
        size="small"
        ok-text="Yes"
        cancel-text="No"
        @ok="deletePriest"
        @close="showDeleteAlert = false"
      />

      <va-data-table
        :items="priests"
        :columns="headers"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        :filter-method="customFilteringFn"
        @filtered="filtered = $event.items"
        striped
      >
        <!-- <template #cell(priestType)="{ rowIndex }">
          <p>{{ getPriestTypeName(rowIndex) }}</p>
        </template> -->
        <template #cell(thumbImage)="{ rowIndex }">
          <va-image :src="getThumbImage(rowIndex)" />
        </template>
        <template #cell(actions)="{ rowIndex }">
          <va-button
            icon-right="create"
            class="mr-2"
            @click="showEditPriestDialog(rowIndex)"
          >
            Edit
          </va-button>
          <va-button
            color="danger"
            icon-right="delete"
            @click="showDeletePriestDialog(rowIndex)"
          >
            Delete
          </va-button>
        </template>
        <template #bodyAppend>
          <tr>
            <td colspan="8" class="table-example--pagination">
              <va-pagination v-model="currentPage" input :pages="pages" />
            </td>
          </tr>
        </template>
      </va-data-table>
    </va-card-content>
  </va-card>
</template>
<script>
import utility from "@/utility/utility";
import PriestsDataService from "../services/priests-data-service";
import AddPriestPage from "./AddPriestPage.vue";
import EditPriestPage from "./EditPriestPage.vue";
import PriestViewModel from "../view_models/priest-view-model";

export default {
  components: {
    AddPriestPage,
    EditPriestPage,
  },
  mounted() {
    this.fetchAllPriests();
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      filtered: [],
      filter: "",
      useCustomFilteringFn: false,
      alertType: "error",
      isDialogActive: false,
      isEditPriestDialogActive: false,
      showDeleteAlert: false,
      selectedPriest: null,
      priests: [],
      headers: [
        { key: "thumbImage", label: "Logo" },
        { key: "name", label: "Name", sortable: true },
        { key: "nameKn", label: "Name Kn", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "descriptionKn", label: "Description Kn", sortable: true },
        { key: "priestType", label: "Designation", sortable: true },
        { key: "span", label: "Reign", sortable: true },
        { key: "actions", width: 80 },
      ],
      filteredCount: 2,
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== 0
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length;
    },
    customFilteringFn() {
      return this.useCustomFilteringFn ? this.filterExact : undefined;
    },
  },

  methods: {
    getThumbImage(rowIndex) {
      const priest = this.priests[rowIndex];
      return priest.thumbnailUrl;
    },
    // getPriestTypeName(rowIndex) {
    //   const priest = this.priests[rowIndex];
    //   const typeName = PriestViewModel.getPriestTypeName(
    //     priest.priestType ?? ""
    //   );
    //   console.log("priest : ", priest.priestType);
    //   console.log("typeName : ", typeName);
    //   return typeName;
    // },
    filterExact(source) {
      if (this.filter === "") {
        return true;
      }

      return source?.toString?.() === this.filter;
    },
    toggleDialog(showDilaog) {
      this.isDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllPriests();
      }
    },
    toggleEditPriestDialog(showDilaog) {
      this.isEditPriestDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllPriests();
      }
    },
    async fetchAllPriests() {
      const priestsResponse = await PriestsDataService.getAllPriests();
      if (priestsResponse.status == 200 && priestsResponse.data != null) {
        // console.log(priestsResponse.data);
        this.priests = priestsResponse.data.priests.map(
          this.getPriestObjectForUI
        );
        this.filtered = this.priests;
      } else {
        // console.log("Failed to fetch priests");
        this.$vaToast.init({
          message: "Failed to fetch priests.",
          color: "danger",
        });
      }
    },
    async deletePriestFromServer() {
      const priestsResponse = await PriestsDataService.deletePriest(
        this.selectedPriest.id
      );
      if (priestsResponse.status == 200) {
        this.fetchAllPriests();
        this.$vaToast.init({
          message: "Successfuly deleted priest.",
          color: "success",
        });
      } else {
        this.$vaToast.init({
          message: "Failed to delete priest.",
          color: "danger",
        });
      }
    },
    getPriestObjectForUI(priestData) {
      return {
        id: priestData.id ?? "",
        name: priestData.name ?? "",
        nameKn: priestData.nameKn ?? "",
        description: priestData.description ?? "",
        descriptionKn: priestData.descriptionKn ?? "",
        priestType: priestData.priestType ?? "",
        span: priestData.span ?? "",
        thumbnailUrl: priestData.thumbnailUrl ?? "",
      };
    },

    showEditPriestDialog(rowIndex) {
      const priest = this.priests[rowIndex];
      this.selectedPriest = priest;
      this.toggleEditPriestDialog(true);
    },

    showDeletePriestDialog(rowIndex) {
      const priest = this.priests[rowIndex];
      this.selectedPriest = priest;
      this.showDeleteAlert = true;
    },

    deletePriest() {
      this.showDeleteAlert = false;
      console.log("ID ", this.selectedPriest.id);
      this.deletePriestFromServer();
    },
  },
};
</script>
 <style lang="scss" scoped>
.table-example--pagination {
  text-align: center;
  text-align: -webkit-center;
}
</style>