<template>
  <div style="display: flex">
    <div style="height: 100vh">
      <va-sidebar color="dark" width="18rem">
        <va-sidebar-item-content>
          <!-- <va-icon name="church" size="large" />
          <va-sidebar-item-title style="font-weight: bold; font-size: 30px"
            >Cascia Admin</va-sidebar-item-title
          > -->

          <div class="flex row">
            <div class="flex">
              <va-image
                style="height: 80px"
                class="mx-0"
                contain
                :src="iconUrl"
              />
            </div>
          </div>
        </va-sidebar-item-content>
        <va-divider />
        <va-sidebar-item
          v-for="(menuItem, menuIndex) in sideMenuList"
          :key="menuItem.id"
          :to="menuItem.path"
          :active="menuItem.active"
          @click="updateSectionStatus(menuIndex)"
        >
          <va-sidebar-item-content>
            <va-icon :name="menuItem.iconName" />
            <va-sidebar-item-title>
              {{ menuItem.title }}
            </va-sidebar-item-title>
          </va-sidebar-item-content>
        </va-sidebar-item>
      </va-sidebar>
    </div>
    <div style="flex: 1">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    iconUrl() {
      return require("@/assets/sidebar_cascia_logo.png");
    },
  },
  mounted() {
    console.log("JUST MOUNTED");
    this.$router.push("/dashboard");
  },
  data() {
    return {
      sideMenuList: [
        {
          title: "Dashboard",
          id: "dashboard",
          iconName: "dashboard",
          path: "/dashboard",
          active: true,
        },
        {
          title: "Timing",
          id: "timing",
          iconName: "schedule",
          path: "/timings",
          active: false,
        },
        {
          title: "Associations",
          id: "associations",
          iconName: "group_work",
          path: "/associations",
          active: false,
        },
        {
          title: "Contacts",
          id: "contacts",
          iconName: "phone",
          path: "/contacts",
          active: false,
        },
        {
          title: "Galleries",
          id: "galleries",
          iconName: "image",
          path: "/galleries",
          active: false,
        },
        {
          title: "Institutes",
          id: "institutes",
          iconName: "school",
          path: "/institutes",
          active: false,
        },
        {
          title: "News",
          id: "news",
          iconName: "newspaper",
          path: "/news",
          active: false,
        },
        {
          title: "Priests",
          id: "priests",
          iconName: "church",
          path: "/priests",
          active: false,
        },
        {
          title: "Wards",
          id: "wards",
          iconName: "group",
          path: "/wards",
          active: false,
        },
        {
          title: "ChurchEvents",
          id: "churchEvents",
          iconName: "festival",
          path: "/churchEvents",
          active: false,
        },
      ],
    };
  },
  methods: {
    updateSectionStatus(index) {
      this.resetSelectionStatus();
      this.sideMenuList[index].active = true;
    },
    resetSelectionStatus() {
      this.sideMenuList.map((item) => (item.active = false));
    },
  },
};
</script>
