<template>
  <va-navbar color="primary">
    <template #left>
      <va-navbar-item>
        <span>{{ title }}</span>
      </va-navbar-item>
    </template>
  </va-navbar>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>