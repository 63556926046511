import httpClient from "../../../network/http_client";

class ChurchEventsDataService {

    getAllChurchEvents() {
        return httpClient.get('/events');
    }
    createChurchEvent(formData) {
        return httpClient.post(
            "/events",
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
    }
    updateChurchEvent(id, data) {
        return httpClient.put(`/events/${id}`, data);
    }
    deleteChurchEvent(id) {
        const deleteUrl = `/events/${id}`;
        return httpClient.delete(`/events/${id}`);
    }
}

export default new ChurchEventsDataService();