import httpClient from "../../../network/http_client";

class NewsDataService {

    getAllNews() {
        return httpClient.get('/news');
    }
    createNews(formData) {
        return httpClient.post(
            "/news",
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
    }
    updateNews(id, data) {
        return httpClient.put(`/news/${id}`, data);
    }
    deleteNews(id) {
        const deleteUrl = `/news/${id}`;
        return httpClient.delete(`/news/${id}`);
    }
}

export default new NewsDataService();