<template>
  <base-header-view title="Gallery"></base-header-view>
  <va-card class="ma-4">
    <va-card-content>
      <div class="mr-2 mt-2 d-flex flex-row-reverse">
        <va-button color="success" @click="toggleDialog(true)">
          Add Gallery +
        </va-button>
      </div>
      <!-- Show Add Event Page -->
      <va-modal
        v-model="isDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <div class="ma-0 pa-0"></div>
        <add-gallery-page @closeDialog="toggleDialog(false)">
        </add-gallery-page>
      </va-modal>

      <va-modal
        v-model="isEditEventDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <edit-gallery-page
          :selectedEvent="selectedEvent"
          @closeDialog="toggleEditEventDialog(false)"
        ></edit-gallery-page>
      </va-modal>

      <va-modal
        v-model="showDeleteAlert"
        message="Do you want to delete this Event ? "
        size="small"
        ok-text="Yes"
        cancel-text="No"
        @ok="deleteEvent"
        @close="showDeleteAlert = false"
      />

      <va-data-table
        :items="events"
        :columns="headers"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        :filter-method="customFilteringFn"
        @filtered="filtered = $event.items"
        striped
      >
        <template #cell(thumbImage)="{ rowIndex }">
          <va-image :src="getThumbImage(rowIndex)" />
        </template>
        <template #cell(eventDate)="{ rowIndex }">
          <p>{{ getFormationDate(rowIndex) }}</p>
        </template>
        <template #cell(actions)="{ rowIndex }">
          <va-button
            icon-right="create"
            class="mr-2"
            @click="showEditEventDialog(rowIndex)"
          >
            Edit
          </va-button>
          <va-button
            color="danger"
            icon-right="delete"
            @click="showDeleteEventDialog(rowIndex)"
          >
            Delete
          </va-button>
        </template>
        <template #bodyAppend>
          <tr>
            <td colspan="8" class="table-example--pagination">
              <va-pagination v-model="currentPage" input :pages="pages" />
            </td>
          </tr>
        </template>
      </va-data-table>
    </va-card-content>
  </va-card>
</template>
<script>
import utility from "@/utility/utility";
import GalleriesDataService from "../services/galleries-data-service";
import AddGalleryPage from "./AddGalleryPage.vue";
import EditGalleryPage from "./EditGalleryPage.vue";

export default {
  components: {
    AddGalleryPage,
    EditGalleryPage,
  },
  mounted() {
    this.fetchAllEvent();
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      filtered: [],
      filter: "",
      useCustomFilteringFn: false,
      alertType: "error",
      isDialogActive: false,
      isEditEventDialogActive: false,
      showDeleteAlert: false,
      selectedEvent: null,
      events: [],
      headers: [
        { key: "thumbImage", label: "Logo" },
        { key: "title", label: "Name", sortable: true },
        { key: "titleKn", label: "Name Kn", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "descriptionKn", label: "Description Kn", sortable: true },
        { key: "location", label: "Location", sortable: true },
        { key: "locationKn", label: "Location Kn", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "eventDate", label: "Event Date", sortable: true },
        { key: "actions", width: 80 },
      ],
      filteredCount: 2,
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== 0
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length;
    },
    customFilteringFn() {
      return this.useCustomFilteringFn ? this.filterExact : undefined;
    },
  },

  methods: {
    getFormationDate(rowIndex) {
      const events = this.events[rowIndex];
      const date = new Date(events.eventDate);
      return `${utility.monthNames[date.getMonth()]} - ${date.getFullYear()}`;
    },
    getThumbImage(rowIndex) {
      const events = this.events[rowIndex];
      return events.thumbnailUrl;
    },
    filterExact(source) {
      if (this.filter === "") {
        return true;
      }

      return source?.toString?.() === this.filter;
    },
    toggleDialog(showDilaog) {
      this.isDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllEvent();
      }
    },
    toggleEditEventDialog(showDilaog) {
      this.isEditEventDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllEvent();
      }
    },
    async fetchAllEvent() {
      const eventsResponse = await GalleriesDataService.getAllGalleries();
      if (eventsResponse.status == 200 && eventsResponse.data != null) {
        console.log(eventsResponse.data);
        this.events = eventsResponse.data.galleries.map(
          this.getEventObjectForUI
        );
        this.filtered = this.events;
      } else {
        console.log("Failed to fetch Event");
        this.$vaToast.init({
          message: "Failed to fetch Event.",
          color: "danger",
        });
      }
    },
    async deleteEventFromServer() {
      const eventsResponse = await GalleriesDataService.deleteGallery(
        this.selectedEvent.id
      );
      if (eventsResponse.status == 200) {
        this.fetchAllEvent();
        this.$vaToast.init({
          message: "Successfuly deleted Event.",
          color: "success",
        });
      } else {
        this.$vaToast.init({
          message: "Failed to delete Event.",
          color: "danger",
        });
      }
    },
    getEventObjectForUI(eventsData) {
      return {
        id: eventsData.id ?? "",
        title: eventsData.title ?? "",
        titleKn: eventsData.titleKn ?? "",
        description: eventsData.description ?? "",
        descriptionKn: eventsData.descriptionKn ?? "",
        location: eventsData.location ?? "",
        locationKn: eventsData.locationKn ?? "",
        type: eventsData.type ?? "",
        eventDate: eventsData.eventDate ?? "",
        thumbnailUrl: eventsData.thumbnailUrl ?? "",
      };
    },

    showEditEventDialog(rowIndex) {
      const events = this.events[rowIndex];
      this.selectedEvent = events;
      this.toggleEditEventDialog(true);
    },

    showDeleteEventDialog(rowIndex) {
      const events = this.events[rowIndex];
      this.selectedEvent = events;
      this.showDeleteAlert = true;
    },

    deleteEvent() {
      this.showDeleteAlert = false;
      console.log("ID ", this.selectedEvent.id);
      this.deleteEventFromServer();
    },
  },
};
</script>
 <style lang="scss" scoped>
.table-example--pagination {
  text-align: center;
  text-align: -webkit-center;
}
</style>