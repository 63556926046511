<template>
  <base-header-view title="News"></base-header-view>
  <va-card class="ma-4">
    <va-card-content>
      <div class="mr-2 mt-2 d-flex flex-row-reverse">
        <va-button color="success" @click="toggleDialog(true)">
          Add News +
        </va-button>
      </div>
      <!-- Show Add News Page -->
      <va-modal
        v-model="isDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <div class="ma-0 pa-0"></div>
        <add-news-page @closeDialog="toggleDialog(false)"> </add-news-page>
      </va-modal>

      <va-modal
        v-model="isEditNewsDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <edit-news-page
          :selectedNews="selectedNews"
          @closeDialog="toggleEditNewsDialog(false)"
        ></edit-news-page>
      </va-modal>

      <va-modal
        v-model="showDeleteAlert"
        message="Do you want to delete this News ? "
        size="small"
        ok-text="Yes"
        cancel-text="No"
        @ok="deleteNews"
        @close="showDeleteAlert = false"
      />

      <va-data-table
        :items="news"
        :columns="headers"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        :filter-method="customFilteringFn"
        @filtered="filtered = $event.items"
        striped
      >
        <template #cell(thumbImage)="{ rowIndex }">
          <va-image :src="getThumbImage(rowIndex)" />
        </template>
        <template #cell(createdDate)="{ rowIndex }">
          <p>{{ getFormationDate(rowIndex) }}</p>
        </template>
        <template #cell(actions)="{ rowIndex }">
          <va-button
            icon-right="create"
            class="mr-2"
            @click="showEditNewsDialog(rowIndex)"
          >
            Edit
          </va-button>
          <va-button
            color="danger"
            icon-right="delete"
            @click="showDeleteNewsDialog(rowIndex)"
          >
            Delete
          </va-button>
        </template>
        <template #bodyAppend>
          <tr>
            <td colspan="8" class="table-example--pagination">
              <va-pagination v-model="currentPage" input :pages="pages" />
            </td>
          </tr>
        </template>
      </va-data-table>
    </va-card-content>
  </va-card>
</template>
<script>
import utility from "@/utility/utility";
import NewsDataService from "../services/news-data-service";
import AddNewsPage from "./AddNewsPage.vue";
import EditNewsPage from "./EditNewsPage.vue";

export default {
  components: {
    AddNewsPage,
    EditNewsPage,
  },
  mounted() {
    this.fetchAllNews();
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      filtered: [],
      filter: "",
      useCustomFilteringFn: false,
      alertType: "error",
      isDialogActive: false,
      isEditNewsDialogActive: false,
      showDeleteAlert: false,
      selectedNews: null,
      news: [],
      headers: [
        { key: "thumbImage", label: "Logo" },
        { key: "title", label: "Name", sortable: true },
        { key: "titleKn", label: "Name Kn", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "descriptionKn", label: "Description Kn", sortable: true },
        { key: "location", label: "Location", sortable: true },
        { key: "locationKn", label: "Location Kn", sortable: true },
        { key: "newsType", label: "Type", sortable: true },
        { key: "createdDate", label: "Created Date", sortable: true },
        { key: "actions", width: 80 },
      ],
      filteredCount: 2,
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== 0
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length;
    },
    customFilteringFn() {
      return this.useCustomFilteringFn ? this.filterExact : undefined;
    },
  },

  methods: {
    getFormationDate(rowIndex) {
      const news = this.news[rowIndex];
      const date = new Date(news.createdDate);
      return `${utility.monthNames[date.getMonth()]} - ${date.getFullYear()}`;
    },
    getThumbImage(rowIndex) {
      const news = this.news[rowIndex];
      return news.photoUrl;
    },
    filterExact(source) {
      if (this.filter === "") {
        return true;
      }

      return source?.toString?.() === this.filter;
    },
    toggleDialog(showDilaog) {
      this.isDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllNews();
      }
    },
    toggleEditNewsDialog(showDilaog) {
      this.isEditNewsDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllNews();
      }
    },
    async fetchAllNews() {
      const newsResponse = await NewsDataService.getAllNews();
      if (newsResponse.status == 200 && newsResponse.data != null) {
        console.log(newsResponse.data);
        this.news = newsResponse.data.news.map(this.getNewsObjectForUI);
        this.filtered = this.news;
      } else {
        console.log("Failed to fetch News");
        this.$vaToast.init({
          message: "Failed to fetch News.",
          color: "danger",
        });
      }
    },
    async deleteNewsFromServer() {
      const newsResponse = await NewsDataService.deleteNews(
        this.selectedNews.id
      );
      if (newsResponse.status == 200) {
        this.fetchAllNews();
        this.$vaToast.init({
          message: "Successfuly deleted News.",
          color: "success",
        });
      } else {
        this.$vaToast.init({
          message: "Failed to delete News.",
          color: "danger",
        });
      }
    },
    getNewsObjectForUI(newsData) {
      return {
        id: newsData.id ?? "",
        title: newsData.title ?? "",
        titleKn: newsData.titleKn ?? "",
        description: newsData.description ?? "",
        descriptionKn: newsData.descriptionKn ?? "",
        location: newsData.location ?? "",
        locationKn: newsData.locationKn ?? "",
        newsType: newsData.newsType ?? "",
        createdDate: newsData.createdDate ?? "",
        photoUrl: newsData.photoUrl ?? "",
      };
    },

    showEditNewsDialog(rowIndex) {
      const news = this.news[rowIndex];
      this.selectedNews = news;
      this.toggleEditNewsDialog(true);
    },

    showDeleteNewsDialog(rowIndex) {
      const news = this.news[rowIndex];
      this.selectedNews = news;
      this.showDeleteAlert = true;
    },

    deleteNews() {
      this.showDeleteAlert = false;
      console.log("ID ", this.selectedNews.id);
      this.deleteNewsFromServer();
    },
  },
};
</script>
 <style lang="scss" scoped>
.table-example--pagination {
  text-align: center;
  text-align: -webkit-center;
}
</style>