<template>
  <base-header-view title="Contacts"></base-header-view>
  <va-card class="ma-4">
    <va-card-content>
      <div class="mr-2 mt-2 d-flex flex-row-reverse">
        <va-button color="success" @click="toggleDialog(true)">
          Add Contact +
        </va-button>
      </div>
      <!-- Show Add Contacts Page -->
      <va-modal
        v-model="isDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <div class="ma-0 pa-0"></div>
        <add-contact-page @closeDialog="toggleDialog(false)"></add-contact-page>
      </va-modal>

      <va-modal
        v-model="isEditContactDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <edit-contact-page
          :selectedContact="selectedContact"
          @closeDialog="toggleEditContactDialog(false)"
        ></edit-contact-page>
      </va-modal>

      <va-modal
        v-model="showDeleteAlert"
        message="Do you want to delete this contact ? "
        size="small"
        ok-text="Yes"
        cancel-text="No"
        @ok="deleteContact"
        @close="showDeleteAlert = false"
      />

      <va-data-table
        :items="contacts"
        :columns="headers"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        :filter-method="customFilteringFn"
        @filtered="filtered = $event.items"
        striped
      >
        <template #cell(actions)="{ rowIndex }">
          <va-button
            icon-right="create"
            class="mr-2"
            @click="showEditContactDialog(rowIndex)"
          >
            Edit
          </va-button>
          <va-button
            color="danger"
            icon-right="delete"
            @click="showDeleteContactDialog(rowIndex)"
          >
            Delete
          </va-button>
        </template>
        <template #bodyAppend>
          <tr>
            <td colspan="8" class="table-example--pagination">
              <va-pagination v-model="currentPage" input :pages="pages" />
            </td>
          </tr>
        </template>
      </va-data-table>
    </va-card-content>
  </va-card>
</template>
<script>
import ContactsDataService from "../services/contacts-data-service";
import WardViewModel from "../../wards/view_models/ward-view-model";
import AddContactPage from "./AddContactPage.vue";
import EditContactPage from "./EditContactPage.vue";

export default {
  components: {
    AddContactPage,
    EditContactPage,
  },
  mounted() {
    this.fetchAllContacts();
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      filtered: [],
      filter: "",
      useCustomFilteringFn: false,
      alertType: "error",
      isDialogActive: false,
      isEditContactDialogActive: false,
      showDeleteAlert: false,
      selectedContact: null,
      contacts: [],
      headers: [
        { key: "name", label: "Name", sortable: true },
        { key: "nameKn", label: "Name Kn", sortable: true },
        { key: "wardName", label: "Ward Name", sortable: true },
        { key: "isGurkar", label: "Gurkar ?", sortable: true },
        { key: "mobileNumber", label: "Mobile Number" },
        { key: "landlineNumber", label: "Landline Number" },
        { key: "actions", width: 80 },
      ],
      filteredCount: 2,
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== 0
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length;
    },
    customFilteringFn() {
      return this.useCustomFilteringFn ? this.filterExact : undefined;
    },
  },

  methods: {
    filterExact(source) {
      if (this.filter === "") {
        return true;
      }

      return source?.toString?.() === this.filter;
    },
    toggleDialog(showDilaog) {
      this.isDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllContacts();
      }
    },
    toggleEditContactDialog(showDilaog) {
      this.isEditContactDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllContacts();
      }
    },
    async fetchAllContacts() {
      const contactsResponse = await ContactsDataService.getAllContacts();
      if (contactsResponse.status == 200 && contactsResponse.data != null) {
        // console.log(contactsResponse.data);
        this.contacts = contactsResponse.data.contacts.map(
          this.getContactObjectForUI
        );
        this.filtered = this.contacts;
      } else {
        // console.log("Failed to fetch contacts");
        this.$vaToast.init({
          message: "Failed to fetch contacts.",
          color: "danger",
        });
      }
    },
    async deleteContactFromServer() {
      const contactsResponse = await ContactsDataService.deleteContact(
        this.selectedContact.id
      );
      if (contactsResponse.status == 200) {
        this.fetchAllContacts();
        this.$vaToast.init({
          message: "Successfuly deleted contact.",
          color: "success",
        });
      } else {
        this.$vaToast.init({
          message: "Failed to delete contact.",
          color: "danger",
        });
      }
    },
    getContactObjectForUI(contactData) {
      return {
        id: contactData.id ?? "",
        name: contactData.name ?? "",
        nameKn: contactData.nameKn ?? "",
        wardName: WardViewModel.getWardName(contactData.wardId ?? ""),
        isGurkar: contactData.isGurkar ?? "",
        mobileNumber: contactData.mobileNumber ?? "",
        landlineNumber: contactData.landlineNumber ?? "",
      };
    },

    showEditContactDialog(rowIndex) {
      const contact = this.contacts[rowIndex];
      this.selectedContact = contact;
      this.toggleEditContactDialog(true);
    },

    showDeleteContactDialog(rowIndex) {
      const contact = this.contacts[rowIndex];
      this.selectedContact = contact;
      this.showDeleteAlert = true;
    },

    deleteContact() {
      this.showDeleteAlert = false;
      console.log("ID ", this.selectedContact.id);
      this.deleteContactFromServer();
    },
  },
};
</script>
 <style lang="scss" scoped>
.table-example--pagination {
  text-align: center;
  text-align: -webkit-center;
}
</style>