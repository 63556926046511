import httpClient from "../../../network/http_client";

class TimingsDataService {

    getAllTimings() {
        return httpClient.get('/timings');
    }
    createTiming(data) {
        return httpClient.post('/timings', data);
    }
    updateTiming(id, data) {
        return httpClient.put(`/timings/${id}`, data);
    }
    deleteTiming(id) {
        const deleteUrl = `/timings/${id}`;
        return httpClient.delete(`/timings/${id}`);
    }
}

export default new TimingsDataService();