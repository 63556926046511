<template>
  <!-- <base-layout-view></base-layout-view> -->
  <router-view />
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
import BaseLayoutView from "./features/base_layout/views/BaseLayoutView.vue";
export default {
  components: { BaseLayoutView },
  name: "App",
};
</script>




