  <template>
  <va-navbar color="#ffffff" class="mb-4 pa-0">
    <template #left>
      <va-navbar-item>
        <va-button
          icon="close"
          class="mr-2"
          @click="dismissDialog"
          flat
        ></va-button>
      </va-navbar-item>
    </template>
    <template #center>
      <va-navbar-item>Edit Institute</va-navbar-item>
    </template>
    <template #right>
      <va-navbar-item>
        <div v-if="showLoading == false" class="mr-4" />

        <va-progress-circle
          v-else
          indeterminate
          size="3rem"
          :thickness="0.2"
          color="danger"
        ></va-progress-circle>
      </va-navbar-item>
    </template>
  </va-navbar>

  <va-form
    ref="instituteForm"
    @validation="validation = $event"
    @submit.prevent="submitForm"
  >
    <div style="min-width: 500px">
      <div class="flex row">
        <div class="flex">
          <va-input
            label="Title"
            class="mb-4 mr-2"
            v-model="title"
            :rules="nameRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Title in Konkani"
            class="mb-4 ml-2"
            v-model="titleKn"
            :rules="nameRules"
          ></va-input>
        </div>
      </div>

      <div class="flex row">
        <div class="flex">
          <va-input
            label="Description"
            class="mb-4 mr-2"
            v-model="description"
            :rules="nameRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Description in Konkani"
            class="mb-4 ml-2"
            v-model="descriptionKn"
            :rules="nameRules"
          ></va-input>
        </div>
      </div>

      <div class="flex row">
        <div class="flex">
          <va-input
            label="Address"
            class="mb-4 mr-2"
            v-model="address"
            :rules="nameRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Address in Konkani"
            class="mb-4 ml-2"
            v-model="addressKn"
            :rules="nameRules"
          ></va-input>
        </div>
      </div>
      <div class="flex row">
        <div class="flex">
          <va-input
            label="Mobile Number"
            class="mb-4 mr-2"
            v-model="mobileNumber"
            :rules="mobileNumberRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Landline Number"
            class="mb-4 ml-2"
            v-model="landlineNumber"
            :rules="landLineNumberRules"
          ></va-input>
        </div>
      </div>

      <va-input
        label="Email"
        class="mb-4"
        v-model="email"
        :rules="emailRules"
      ></va-input>

      <!-- <div class="flex row">
        <div class="flex"> -->
      <va-date-input v-model="createdDate" class="mb-4" :format="formatDate">
      </va-date-input>
      <!-- </div>
      </div> -->
      <div class="flex row">
        <div class="flex">
          <p
            class="mb-2 pl-2"
            :style="{
              color: thumbnailUrl.length == 0 ? '#FF0000' : '#111111',
            }"
          >
            Institute logo image:
          </p>
          <div style="display: flex; justify-content: flex-start" class="mb-4">
            <va-file-upload
              v-model="thumbnailImages"
              type="single"
              file-types="image/*"
            />
          </div>
        </div>
        <div class="flex">
          <div class="row">
            <div class="flex md6 lg4">
              <va-image
                v-show="showThumbnailImage"
                style="min-width: 100px"
                :src="thumbnailUrl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </va-form>
  <va-button color="success" @click="submitForm"> Save </va-button>
  <va-button color="warning" class="ml-2" @click="clear">Clear</va-button>
</template>

<script>
import utility from "@/utility/utility";
import InstituteDataService from "../services/institute-data-service";
export default {
  props: {
    isVisible: Boolean,
    selectedInstitute: null,
  },
  mounted() {
    if (this.selectedInstitute != null) {
      this.title = this.selectedInstitute.title;
      this.titleKn = this.selectedInstitute.titleKn;
      this.description = this.selectedInstitute.description;
      this.descriptionKn = this.selectedInstitute.descriptionKn;
      this.address = this.selectedInstitute.address;
      this.addressKn = this.selectedInstitute.addressKn;
      this.mobileNumber = this.selectedInstitute.mobileNumber;
      this.landlineNumber = this.selectedInstitute.landlineNumber;
      this.email = this.selectedInstitute.email;
      this.createdDate = new Date(this.selectedInstitute.createdDate);
      this.thumbnailUrl = this.selectedInstitute.thumbnailUrl;
      console.log("thumbnailUrl", this.thumbnailUrl);
    }
  },
  emits: ["closeDialog"],
  data: () => ({
    thumbnailImages: [],
    validation: null,
    title: "",
    titleKn: "",
    description: "",
    descriptionKn: "",
    address: "",
    addressKn: "",
    createdDate: new Date(),
    mobileNumber: "",
    landlineNumber: "",
    email: "",
    showLoading: false,
    thumbnailUrl: "",
    nameRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length > 1) || "Name must be atleast 1 characters long",
    ],
    mobileNumberRules: [
      (v) => !!v || "Mobile Number is required",
      (v) =>
        (v && v.length >= 10) ||
        "Mobile Number must be minimum of 10 characters long",
    ],
    landLineNumberRules: [
      (v) =>
        (v && v.length >= 10) ||
        "Landline Number must be minimum of 10 characters long",
    ],
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid",
    ],
  }),
  computed: {
    showThumbnailImage() {
      console.log("thumbnailImages length", this.thumbnailImages.length);
      return this.thumbnailUrl.length > 0 && this.thumbnailImages.length == 0;
    },
  },
  methods: {
    formatDate(date) {
      return `${utility.monthNames[date.getMonth()]} - ${date.getFullYear()}`;
    },
    dismissDialog() {
      this.$emit("closeDialog");
    },
    submitForm() {
      this.$refs.instituteForm.validate();
      console.log("validation is ", this.validation);
      if (
        this.validation != null &&
        this.validation != false &&
        (this.thumbnailImages.length > 0 || this.thumbnailUrl.length > 0)
      ) {
        this.showLoading = true;
        this.uploadInstitute();
      } else {
        this.$vaToast.init({
          message: "Please fill the required fields.",
          color: "danger",
        });
      }
    },
    validate() {
      // if (
      //   this.title.length > 0 &&
      //   this.title.length > 0 &&
      //   this.description.length > 0 &&
      //   this.description.length > 0 &&
      //   this.thumbnailImages.length > 0
      // ) {
      //   this.valid = true;
      // }
    },
    clear() {
      this.resetAllData();
    },
    resetAllData() {
      this.thumbnailImages = [];
      this.validation = null;
      this.title = "";
      this.titleKn = "";
      this.description = "";
      this.descriptionKn = "";
      this.address = "";
      this.addressKn = "";
      this.createdDate = new Date();
      this.showLoading = false;
      this.mobileNumber = "";
      this.landlineNumber = "";
      this.email = "";
      // this.$refs.instituteForm.reset();
      // this.$refs.instituteForm.resetValidation();
    },
    async uploadInstitute() {
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("titleKn", this.titleKn);
      formData.append("description", this.description);
      formData.append("descriptionKn", this.descriptionKn);
      formData.append("address", this.address);
      formData.append("addressKn", this.addressKn);
      formData.append("mobileNumber", this.mobileNumber);
      formData.append("landlineNumber", this.landlineNumber);
      formData.append("email", this.email);
      formData.append("createdDate", this.createdDate.getTime());
      if (this.thumbnailImages.length > 0) {
        formData.append("imageFile", this.thumbnailImages[0]);
      }

      try {
        const instituteResponse = await InstituteDataService.updateInstitute(
          this.selectedInstitute.id,
          formData
        );
        if (instituteResponse.status == 200 && instituteResponse.data != null) {
          this.resetAllData();
          this.dismissDialog();
          this.$vaToast.init({
            message: "Successfuly created a institute.",
            color: "success",
          });
        } else {
          this.showLoading = false;
          this.$vaToast.init({
            message: "Failed to upload institute. Please try again.",
            color: "danger",
          });
        }
      } catch (error) {
        this.showLoading = false;
        console.log(error);
        this.$vaToast.init({
          message:
            "Failed to upload institute. Please check all your fields and try again.",
          color: "danger",
        });
      }
    },
  },
};
</script>
