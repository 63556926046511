import httpClient from "../../../network/http_client";

class InstitutesDataService {

    getAllInstitutes() {
        return httpClient.get('/institutes');
    }
    createInstitute(formData) {
        return httpClient.post(
            "/institutes",
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
    }
    updateInstitute(id, data) {
        return httpClient.put(`/institutes/${id}`, data);
    }
    deleteInstitute(id) {
        const deleteUrl = `/institutes/${id}`;
        return httpClient.delete(`/institutes/${id}`);
    }
}

export default new InstitutesDataService();