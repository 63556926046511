  <template>
  <va-navbar color="#ffffff" class="mb-4 pa-0">
    <template #left>
      <va-navbar-item>
        <va-button
          icon="close"
          class="mr-2"
          @click="dismissDialog"
          flat
        ></va-button>
      </va-navbar-item>
    </template>
    <template #center>
      <va-navbar-item>Edit Event</va-navbar-item>
    </template>
    <template #right>
      <va-navbar-item>
        <div v-if="showLoading == false" class="mr-4" />

        <va-progress-circle
          v-else
          indeterminate
          size="3rem"
          :thickness="0.2"
          color="danger"
        ></va-progress-circle>
      </va-navbar-item>
    </template>
  </va-navbar>

  <va-form
    ref="eventsForm"
    @validation="validation = $event"
    @submit.prevent="submitForm"
  >
    <div style="min-width: 500px">
      <div class="flex row">
        <div class="flex">
          <va-input
            label="Title"
            class="mb-4 mr-2"
            v-model="title"
            :rules="nameRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Title in Konkani"
            class="mb-4 ml-2"
            v-model="titleKn"
            :rules="nameRules"
          ></va-input>
        </div>
      </div>

      <div class="flex row">
        <div class="flex">
          <va-input
            label="Description"
            class="mb-4 mr-2"
            v-model="description"
            :rules="nameRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Description in Konkani"
            class="mb-4 ml-2"
            v-model="descriptionKn"
            :rules="nameRules"
          ></va-input>
        </div>
      </div>

      <div class="flex row">
        <div class="flex">
          <va-input
            label="Location"
            class="mb-4 mr-2"
            v-model="location"
            :rules="nameRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Location in Konkani"
            class="mb-4 ml-2"
            v-model="locationKn"
            :rules="nameRules"
          ></va-input>
        </div>
      </div>

      <va-select
        class="mb-4"
        :options="eventTypes"
        label="Event Type"
        v-model="selectedEventType"
        :items="eventTypes"
        :rules="[(v) => !!v || 'Event Type is required']"
      />

      <!-- <div class="flex row">
        <div class="flex"> -->
      <va-date-input v-model="eventDate" class="mb-4" :format="formatDate">
      </va-date-input>
      <!-- </div>
      </div> -->
      <div class="flex row">
        <div class="flex">
          <p
            class="mb-2 pl-2"
            :style="{
              color: thumbnailUrl.length == 0 ? '#FF0000' : '#111111',
            }"
          >
            Event logo image:
          </p>
          <div style="display: flex; justify-content: flex-start" class="mb-4">
            <va-file-upload
              v-model="thumbnailImages"
              type="single"
              file-types="image/*"
            />
          </div>
        </div>
        <div class="flex">
          <div class="row">
            <div class="flex md6 lg4">
              <va-image
                v-show="showThumbnailImage"
                style="min-width: 100px"
                :src="thumbnailUrl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </va-form>
  <va-button color="success" @click="submitForm"> Save </va-button>
  <va-button color="warning" class="ml-2" @click="clear">Clear</va-button>
</template>

<script>
import utility from "@/utility/utility";
import ChurchEventsDataService from "../services/church-events-data-service";
import ChurchEventsViewModel from "../view_models/church-events-view-model";
export default {
  props: {
    isVisible: Boolean,
    selectedEvent: null,
  },
  mounted() {
    if (this.selectedEvent != null) {
      this.title = this.selectedEvent.title;
      this.titleKn = this.selectedEvent.titleKn;
      this.description = this.selectedEvent.description;
      this.descriptionKn = this.selectedEvent.descriptionKn;
      this.location = this.selectedEvent.location;
      this.locationKn = this.selectedEvent.locationKn;
      this.selectedEventType = this.selectedEvent.type;
      this.eventDate = new Date(this.selectedEvent.eventDate);
      this.thumbnailUrl = this.selectedEvent.thumbnailUrl;
    }
  },
  emits: ["closeDialog"],
  data: () => ({
    thumbnailImages: [],
    validation: null,
    title: "",
    titleKn: "",
    description: "",
    descriptionKn: "",
    location: "",
    locationKn: "",
    eventDate: new Date(),
    selectedEventType: "",
    eventTypes: ChurchEventsViewModel.getAllEventsType(),
    showLoading: false,
    thumbnailUrl: "",
    nameRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length > 1) || "Name must be atleast 1 characters long",
    ],
  }),
  computed: {
    showThumbnailImage() {
      console.log("thumbnailImages length", this.thumbnailImages.length);
      return this.thumbnailUrl.length > 0 && this.thumbnailImages.length == 0;
    },
  },
  methods: {
    formatDate(date) {
      return `${utility.monthNames[date.getMonth()]} - ${date.getFullYear()}`;
    },
    dismissDialog() {
      this.$emit("closeDialog");
    },
    submitForm() {
      this.$refs.eventsForm.validate();
      console.log("validation is ", this.validation);
      if (
        this.validation != null &&
        this.validation != false &&
        (this.thumbnailImages.length > 0 || this.thumbnailUrl.length > 0)
      ) {
        this.showLoading = true;
        this.uploadEvent();
      } else {
        this.$vaToast.init({
          message: "Please fill the required fields.",
          color: "danger",
        });
      }
    },
    validate() {
      // if (
      //   this.title.length > 0 &&
      //   this.title.length > 0 &&
      //   this.description.length > 0 &&
      //   this.description.length > 0 &&
      //   this.thumbnailImages.length > 0
      // ) {
      //   this.valid = true;
      // }
    },
    clear() {
      this.resetAllData();
    },
    resetAllData() {
      this.thumbnailImages = [];
      this.validation = null;
      this.title = "";
      this.titleKn = "";
      this.description = "";
      this.descriptionKn = "";
      this.location = "";
      this.locationKn = "";
      this.eventDate = new Date();
      this.showLoading = false;
      this.selectedEventType = "";
      // this.$refs.eventsForm.reset();
      // this.$refs.eventsForm.resetValidation();
    },
    async uploadEvent() {
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("titleKn", this.titleKn);
      formData.append("description", this.description);
      formData.append("descriptionKn", this.descriptionKn);
      formData.append("location", this.location);
      formData.append("locationKn", this.locationKn);
      formData.append("type", this.selectedEventType);
      formData.append("eventDate", this.eventDate.getTime());
      if (this.thumbnailImages.length > 0) {
        formData.append("imageFile", this.thumbnailImages[0]);
      }

      try {
        const eventsResponse = await ChurchEventsDataService.updateChurchEvent(
          this.selectedEvent.id,
          formData
        );
        if (eventsResponse.status == 200 && eventsResponse.data != null) {
          this.resetAllData();
          this.dismissDialog();
          this.$vaToast.init({
            message: "Successfuly created a events.",
            color: "success",
          });
        } else {
          this.showLoading = false;
          this.$vaToast.init({
            message: "Failed to upload events. Please try again.",
            color: "danger",
          });
        }
      } catch (error) {
        this.showLoading = false;
        console.log(error);
        this.$vaToast.init({
          message:
            "Failed to upload events. Please check all your fields and try again.",
          color: "danger",
        });
      }
    },
  },
};
</script>
