class WardViewModel {

    // Replace with fetch all wards from backend
    getAllWards() {
        return [
            "St. Antony Ward",
            "St. Francis Xavier Ward",
            "St. Peter Ward",
            "Infant Jesus Ward",
            "Nityadhar Mai Ward",
            "St. Paul Ward",
            "St. Rita Ward",
            "St. Joseph Ward",
            "Infant Mary Ward",
            "St. Sebastian Ward",
        ];
    }

    getWardId(wardName) {
        return this.getAllWards().indexOf(wardName);
    }

    getWardName(wardId) {
        const index = parseInt(wardId);

        return this.getAllWards()[index];
    }
}

export default new WardViewModel()