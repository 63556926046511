<template>
  <base-header-view title="Wards"></base-header-view>
  <va-card class="ma-4">
    <va-card-content>
      <div class="mr-2 mt-2 d-flex flex-row-reverse">
        <va-button color="success" @click="toggleDialog(true)">
          Add Ward +
        </va-button>
      </div>
      <!-- Show Add Wards Page -->
      <va-modal
        v-model="isDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <div class="ma-0 pa-0"></div>
        <add-ward-page @closeDialog="toggleDialog(false)"> </add-ward-page>
      </va-modal>

      <va-modal
        v-model="isEditWardDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <edit-ward-page
          :selectedWard="selectedWard"
          @closeDialog="toggleEditWardDialog(false)"
        ></edit-ward-page>
      </va-modal>

      <va-modal
        v-model="showDeleteAlert"
        message="Do you want to delete this ward ? "
        size="small"
        ok-text="Yes"
        cancel-text="No"
        @ok="deleteWard"
        @close="showDeleteAlert = false"
      />

      <va-data-table
        :items="wards"
        :columns="headers"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        :filter-method="customFilteringFn"
        @filtered="filtered = $event.items"
        striped
      >
        <template #cell(thumbImage)="{ rowIndex }">
          <va-image :src="getThumbImage(rowIndex)" />
        </template>
        <template #cell(formationDate)="{ rowIndex }">
          <p>{{ getFormationDate(rowIndex) }}</p>
        </template>
        <template #cell(actions)="{ rowIndex }">
          <va-button
            icon-right="create"
            class="mr-2"
            @click="showEditWardDialog(rowIndex)"
          >
            Edit
          </va-button>
          <va-button
            color="danger"
            icon-right="delete"
            @click="showDeleteWardDialog(rowIndex)"
          >
            Delete
          </va-button>
        </template>
        <template #bodyAppend>
          <tr>
            <td colspan="8" class="table-example--pagination">
              <va-pagination v-model="currentPage" input :pages="pages" />
            </td>
          </tr>
        </template>
      </va-data-table>
    </va-card-content>
  </va-card>
</template>
<script>
import utility from "@/utility/utility";
import WardsDataService from "../services/wards-data-service";
import AddWardPage from "./AddWardPage.vue";
import EditWardPage from "./EditWardPage.vue";
import WardViewModel from "../view_models/ward-view-model";

export default {
  components: {
    AddWardPage,
    EditWardPage,
  },
  mounted() {
    this.fetchAllWards();
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      filtered: [],
      filter: "",
      useCustomFilteringFn: false,
      alertType: "error",
      isDialogActive: false,
      isEditWardDialogActive: false,
      showDeleteAlert: false,
      selectedWard: null,
      wards: [],
      headers: [
        { key: "thumbImage", label: "Logo" },
        { key: "title", label: "Name", sortable: true },
        { key: "titleKn", label: "Name Kn", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "descriptionKn", label: "Description Kn", sortable: true },
        { key: "formationDate", label: "Formation Date", sortable: true },
        { key: "actions", width: 80 },
      ],
      filteredCount: 2,
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== 0
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length;
    },
    customFilteringFn() {
      return this.useCustomFilteringFn ? this.filterExact : undefined;
    },
  },

  methods: {
    getFormationDate(rowIndex) {
      const ward = this.wards[rowIndex];
      const date = new Date(ward.formationDate);
      return `${utility.monthNames[date.getMonth()]} - ${date.getFullYear()}`;
    },
    getThumbImage(rowIndex) {
      const ward = this.wards[rowIndex];
      return ward.thumbnailUrl;
    },
    filterExact(source) {
      if (this.filter === "") {
        return true;
      }

      return source?.toString?.() === this.filter;
    },
    toggleDialog(showDilaog) {
      this.isDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllWards();
      }
    },
    toggleEditWardDialog(showDilaog) {
      this.isEditWardDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllWards();
      }
    },
    async fetchAllWards() {
      const wardsResponse = await WardsDataService.getAllWards();
      if (wardsResponse.status == 200 && wardsResponse.data != null) {
        // console.log(wardsResponse.data);
        this.wards = wardsResponse.data.wards.map(this.getWardObjectForUI);
        this.filtered = this.wards;
      } else {
        // console.log("Failed to fetch wards");
        this.$vaToast.init({
          message: "Failed to fetch wards.",
          color: "danger",
        });
      }
    },
    async deleteWardFromServer() {
      const wardsResponse = await WardsDataService.deleteWard(
        this.selectedWard.id
      );
      if (wardsResponse.status == 200) {
        this.fetchAllWards();
        this.$vaToast.init({
          message: "Successfuly deleted ward.",
          color: "success",
        });
      } else {
        this.$vaToast.init({
          message: "Failed to delete ward.",
          color: "danger",
        });
      }
    },
    getWardObjectForUI(wardData) {
      return {
        id: wardData.id ?? "",
        title: wardData.title ?? "",
        titleKn: wardData.titleKn ?? "",
        description: wardData.description ?? "",
        descriptionKn: wardData.descriptionKn ?? "",
        formationDate: wardData.formationDate ?? "",
        thumbnailUrl: wardData.thumbnailUrl ?? "",
      };
    },

    showEditWardDialog(rowIndex) {
      const ward = this.wards[rowIndex];
      this.selectedWard = ward;
      this.toggleEditWardDialog(true);
    },

    showDeleteWardDialog(rowIndex) {
      const ward = this.wards[rowIndex];
      this.selectedWard = ward;
      this.showDeleteAlert = true;
    },

    deleteWard() {
      this.showDeleteAlert = false;
      console.log("ID ", this.selectedWard.id);
      this.deleteWardFromServer();
    },
  },
};
</script>
 <style lang="scss" scoped>
.table-example--pagination {
  text-align: center;
  text-align: -webkit-center;
}
</style>