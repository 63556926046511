import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import { VuesticPlugin } from "vuestic-ui";
import { createPinia } from 'pinia'
import "vuestic-ui/dist/vuestic-ui.css";
import BaseHeaderView from "../src/common_components/BaseHeaderView.vue"

const app = createApp(App);

app.component('base-header-view', BaseHeaderView);
app.use(createPinia());
app.use(router)
    .use(VuesticPlugin)
    .mount("#app");
