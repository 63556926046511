import httpClient from "../../../network/http_client";

class GalleriesDataService {

    getAllGalleries() {
        return httpClient.get('/gallery');
    }
    createGallery(formData) {
        return httpClient.post(
            "/gallery",
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
    }
    updateGallery(id, data) {
        return httpClient.put(`/gallery/${id}`, data);
    }
    deleteGallery(id) {
        const deleteUrl = `/gallery/${id}`;
        return httpClient.delete(`/gallery/${id}`);
    }
}

export default new GalleriesDataService();