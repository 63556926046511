class PriestViewModel {

    // Replace with fetch all wards from backend
    getAllPriestTypes() {
        return [
            "assistant",
            "main"
        ];
    }

    getPriestTypeId(priestName) {
        return this.getAllPriestTypes().indexOf(priestName);
    }

    getPriestTypeName(priestId) {
        const index = parseInt(priestId);

        return this.getAllPriestTypes()[index];
    }
}

export default new PriestViewModel()