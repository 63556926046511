import httpClient from "../../../network/http_client";

class SignInDataService {


    signInUser(data) {
        return httpClient.post('/users/sign-in', data);
    }

    // signUpUser(data) {
    //     return httpClient.post('/users/sign-up', data);
    // }

}

export default new SignInDataService();