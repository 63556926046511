<template>
  <base-header-view title="Timings"></base-header-view>
  <va-card class="ma-4">
    <va-card-content>
      <div class="mr-2 mt-2 d-flex flex-row-reverse">
        <va-button color="success" @click="toggleDialog(true)">
          Add Mass Timing +
        </va-button>
      </div>
      <!-- Show Add Contacts Page -->
      <va-modal
        v-model="isDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <div class="ma-0 pa-0"></div>
        <add-timing-page @closeDialog="toggleDialog(false)"></add-timing-page>
      </va-modal>

      <va-modal
        v-model="isEditDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <edit-timing-page
          :selectedTiming="selectedTiming"
          @closeDialog="toggleEditTimingDialog(false)"
        ></edit-timing-page>
      </va-modal>

      <va-modal
        v-model="showDeleteAlert"
        message="Do you want to delete this timing ? "
        size="small"
        ok-text="Yes"
        cancel-text="No"
        @ok="deleteTiming"
        @close="showDeleteAlert = false"
      />

      <va-data-table
        :items="timings"
        :columns="headers"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        :filter-method="customFilteringFn"
        @filtered="filtered = $event.items"
        striped
      >
        <template #cell(actions)="{ rowIndex }">
          <va-button
            icon-right="create"
            class="mr-2"
            @click="showEditTimingDialog(rowIndex)"
          >
            Edit
          </va-button>
          <va-button
            color="danger"
            icon-right="delete"
            @click="showDeleteTimingDialog(rowIndex)"
          >
            Delete
          </va-button>
        </template>

        <template #bodyAppend>
          <tr>
            <td colspan="8" class="table-example--pagination">
              <va-pagination v-model="currentPage" input :pages="pages" />
            </td>
          </tr>
        </template>
      </va-data-table>
    </va-card-content>
  </va-card>
</template>
<script>
import TimingsDataService from "../services/timings-data-service";
import AddTimingPage from "./AddTimingPage.vue";
import EditTimingPage from "./EditTimingPage.vue";

export default {
  components: {
    AddTimingPage,
    EditTimingPage,
  },
  mounted() {
    this.fetchAllTimings();
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== 0
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length;
    },
    customFilteringFn() {
      return this.useCustomFilteringFn ? this.filterExact : undefined;
    },
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      filtered: [],
      filter: "",
      useCustomFilteringFn: false,
      alertType: "error",
      isDialogActive: false,
      isEditDialogActive: false,
      showDeleteAlert: false,
      selectedTiming: null,
      timings: [],
      headers: [
        { key: "day", label: "Day", sortable: false },
        { key: "dayKn", label: "Day Kn", sortable: false },
        { key: "timing", label: "Timing", sortable: false },
        { key: "timingKn", label: "Timing Kn", sortable: false },
        { key: "language", label: "Language", sortable: false },
        { key: "languageKn", label: "Language Kn", sortable: false },
        { key: "actions", width: 80 },
      ],
    };
  },

  methods: {
    filterExact(source) {
      if (this.filter === "") {
        return true;
      }

      return source?.toString?.() === this.filter;
    },
    toggleDialog(showDilaog) {
      this.isDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllTimings();
      }
    },
    toggleEditTimingDialog(showDilaog) {
      this.isEditDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllTimings();
      }
    },
    async fetchAllTimings() {
      const timingResponse = await TimingsDataService.getAllTimings();
      if (timingResponse.status == 200 && timingResponse.data != null) {
        // console.log(timingResponse.data);
        this.timings = timingResponse.data.timings.map(
          this.getTimingObjectForUI
        );
        this.filtered = this.timings;
      } else {
        // console.log("Failed to fetch timings");
        this.$vaToast.init({
          message: "Failed to fetch timings.",
          color: "danger",
        });
      }
    },
    async deleteTimingFromServer() {
      const timingsResponse = await TimingsDataService.deleteTiming(
        this.selectedTiming.id
      );
      if (timingsResponse.status == 200) {
        this.fetchAllTimings();
        this.$vaToast.init({
          message: "Successfuly deleted timing.",
          color: "success",
        });
      } else {
        this.$vaToast.init({
          message: "Failed to delete timing.",
          color: "danger",
        });
      }
    },
    getTimingObjectForUI(timingData) {
      // console.log("timingData.timing : ", timingData.timing);
      return {
        id: timingData.id ?? "",
        day: timingData.day ?? "",
        dayKn: timingData.dayKn ?? "",
        language: timingData.language ?? "",
        languageKn: timingData.languageKn ?? "",
        timing: timingData.timing ?? "",
        timingKn: timingData.timingKn ?? "",
      };
    },

    showEditTimingDialog(rowIndex) {
      const timing = this.timings[rowIndex];
      this.selectedTiming = timing;
      this.toggleEditTimingDialog(true);
    },

    showDeleteTimingDialog(rowIndex) {
      const timing = this.timings[rowIndex];
      this.selectedTiming = timing;
      this.showDeleteAlert = true;
    },

    deleteTiming() {
      this.showDeleteAlert = false;
      console.log("ID ", this.selectedTiming.id);
      this.deleteTimingFromServer();
    },
  },
};
</script>
<style lang="scss" scoped>
.table-example--pagination {
  text-align: center;
  text-align: -webkit-center;
}
</style>