import httpClient from "../../../network/http_client";

class ContactsDataService {

    getAllContacts() {
        return httpClient.get('/contacts');
    }
    createContact(data) {
        return httpClient.post('/contacts', data);
    }
    updateContact(id, data) {
        return httpClient.put(`/contacts/${id}`, data);
    }
    deleteContact(id) {
        const deleteUrl = `/contacts/${id}`;
        return httpClient.delete(`/contacts/${id}`);
    }
}

export default new ContactsDataService();