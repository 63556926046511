<template>
  <!-- <div class="pa-0"> -->
  <!-- </div> -->
  <va-navbar color="primary">
    <template #left>
      <va-navbar-item>
        <span>Dashboard</span>
      </va-navbar-item>
    </template>
    <template #right>
      <va-navbar-item>
        <va-button @click="showDeleteAlertDialog" icon="logout" class="mr-4" />
      </va-navbar-item>
    </template>
  </va-navbar>
  <va-modal
    v-model="showDeleteAlert"
    message="Do you want to logout ? "
    size="small"
    ok-text="Yes"
    cancel-text="No"
    @ok="logout"
    @close="showDeleteAlert = false"
  />
  <div class="flex row">
    <div class="flex">
      <va-image style="height: 500px" class="ma-16" contain :src="imageURL" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { authStore } from "@/store/auth_store";
export default {
  data() {
    return {
      showDeleteAlert: false,
      imageURL:
        "https://res.cloudinary.com/dzyj8elg4/image/upload/v1650893950/cascia_church_gallery/default_photos/cascia_church_jmpb0f.jpg",
    };
  },
  methods: {
    ...mapActions(authStore, ["clearToken"]),
    showDeleteAlertDialog() {
      this.showDeleteAlert = true;
    },
    logout() {
      this.clearToken();
      this.showDeleteAlert = false;
      this.$router.replace("/signIn");
    },
  },
};
</script>