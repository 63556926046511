  <template>
  <va-navbar color="#ffffff" class="mb-4 pa-0">
    <template #left>
      <va-navbar-item>
        <va-button
          icon="close"
          class="mr-2"
          @click="dismissDialog"
          flat
        ></va-button>
      </va-navbar-item>
    </template>
    <template #center>
      <va-navbar-item>Add Timing</va-navbar-item>
    </template>
    <template #right>
      <va-navbar-item>
        <div v-if="showLoading == false" class="mr-4" />

        <va-progress-circle
          v-else
          indeterminate
          size="3rem"
          :thickness="0.2"
          color="danger"
        ></va-progress-circle>
      </va-navbar-item>
    </template>
  </va-navbar>
  <va-form
    ref="timingForm"
    @validation="validation = $event"
    @submit.prevent="submitForm"
  >
    <div style="min-width: 500px">
      <div class="flex row">
        <div class="flex">
          <va-input
            label="Day"
            class="mb-4 mr-2"
            v-model="day"
            :rules="dayRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Day in Konkani"
            class="mb-4 ml-2"
            v-model="dayKn"
            :rules="dayRules"
          ></va-input>
        </div>
      </div>
      <div class="flex row">
        <div class="flex">
          <va-input
            label="Timing"
            class="mb-4 mr-2"
            v-model="timing"
            :rules="dayRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Timing in Konkani"
            class="mb-4 ml-2"
            v-model="timingKn"
            :rules="dayRules"
          ></va-input>
        </div>
      </div>
      <div class="flex row">
        <div class="flex">
          <va-input
            label="Language"
            class="mb-4 mr-2"
            v-model="language"
            :rules="dayRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Language in Konkani"
            class="mb-4 ml-2"
            v-model="languageKn"
            :rules="dayRules"
          ></va-input>
        </div>
      </div>
    </div>
  </va-form>
  <va-button color="success" @click="submitForm"> Save </va-button>
  <va-button color="warning" class="ml-2" @click="clear">Clear</va-button>
</template>

<script>
import TimingsDataService from "../services/timings-data-service";
export default {
  props: {
    isVisible: Boolean,
  },
  emits: ["closeDialog"],
  data: () => ({
    validation: null,
    day: "",
    dayKn: "",
    timing: "",
    timingKn: "",
    language: "",
    languageKn: "",

    showLoading: false,
    dayRules: [
      (v) => !!v || "This field is required",
      (v) =>
        (v && v.length > 3) || "This Field must be atleast 3 characters long",
    ],
  }),

  methods: {
    dismissDialog() {
      this.$emit("closeDialog");
    },
    submitForm() {
      //this.validate();
      this.$refs.timingForm.validate();
      console.log("validation is ", this.validation);
      if (this.validation != null && this.validation != false) {
        this.showLoading = true;
        // setTimeout(() => {
        this.uploadTiming();
        //}, 3000);
      } else {
        this.$vaToast.init({
          message: "Please fill the required fields.",
          color: "danger",
        });
      }
    },
    validate() {
      // if (
      //   this.day.length > 0 &&
      //   this.dayKn.length > 0 &&
      //   this.mobileNumber.length > 0 &&
      //   this.selectedWard.length > 0
      // ) {
      //   this.valid = true;
      // }
    },
    clear() {
      this.resetAllData();
    },
    resetAllData() {
      this.validation = null;
      this.day = "";
      this.dayKn = "";
      this.timing = "";
      this.timingKn = "";
      this.language = "";
      this.languageKn = "";
      this.showLoading = false;
      // this.$refs.timingForm.reset();
      // this.$refs.timingForm.resetValidation();
    },
    async uploadTiming() {
      const timingsJson = {
        day: this.day,
        dayKn: this.dayKn,
        timing: this.timing,
        timingKn: this.timingKn,
        language: this.language,
        languageKn: this.languageKn,
      };

      try {
        const timingsResponse = await TimingsDataService.createTiming(
          timingsJson
        );
        if (timingsResponse.status == 200 && timingsResponse.data != null) {
          this.resetAllData();
          this.dismissDialog();
          this.$vaToast.init({
            message: "Successfuly created a timing.",
            color: "success",
          });
        } else {
          this.showLoading = false;

          this.$vaToast.init({
            message: "Failed to upload timing. Please try again.",
            color: "danger",
          });
        }
      } catch (error) {
        this.showLoading = false;
        console.log(error);
        this.$vaToast.init({
          message:
            "Failed to upload timing. Please check all your fields and try again.",
          color: "danger",
        });
      }
    },
  },
};
</script>
