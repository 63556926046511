class ChurchEventsViewModel {

    getAllEventsType() {
        return [
            "association",
            "parish",
            "ward"
        ];
    }

    getEventsTypeId(eventTypeName) {
        return this.getAllEventsType().indexOf(eventTypeName);
    }

    getEventsTypeName(eventId) {
        const index = parseInt(eventId);

        return this.getAllEventsType()[index];
    }

}

export default new ChurchEventsViewModel()