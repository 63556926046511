class NewsViewModel {

    getAllNewsType() {
        return [
            "announcements",
            "obituary",
            "bulletin"
        ];
    }

    getNewsTypeId(newsTypeName) {
        return this.getAllNewsType().indexOf(newsTypeName);
    }

    getNewsTypeName(newsId) {
        const index = parseInt(newsId);

        return this.getAllNewsType()[index];
    }

}

export default new NewsViewModel()