  <template>
  <va-navbar color="#ffffff" class="mb-4 pa-0">
    <template #left>
      <va-navbar-item>
        <va-button
          icon="close"
          class="mr-2"
          @click="dismissDialog"
          flat
        ></va-button>
      </va-navbar-item>
    </template>
    <template #center>
      <va-navbar-item>Add News</va-navbar-item>
    </template>
    <template #right>
      <va-navbar-item>
        <div v-if="showLoading == false" class="mr-4" />

        <va-progress-circle
          v-else
          indeterminate
          size="3rem"
          :thickness="0.2"
          color="danger"
        ></va-progress-circle>
      </va-navbar-item>
    </template>
  </va-navbar>

  <va-form
    ref="newsForm"
    @validation="validation = $event"
    @submit.prevent="submitForm"
  >
    <div style="min-width: 500px">
      <div class="flex row">
        <div class="flex">
          <va-input
            label="Title"
            class="mb-4 mr-2"
            v-model="title"
            :rules="nameRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Title in Konkani"
            class="mb-4 ml-2"
            v-model="titleKn"
            :rules="nameRules"
          ></va-input>
        </div>
      </div>

      <div class="flex row">
        <div class="flex">
          <va-input
            label="Description"
            class="mb-4 mr-2"
            v-model="description"
            :rules="nameRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Description in Konkani"
            class="mb-4 ml-2"
            v-model="descriptionKn"
            :rules="nameRules"
          ></va-input>
        </div>
      </div>

      <div class="flex row">
        <div class="flex">
          <va-input
            label="Location"
            class="mb-4 mr-2"
            v-model="location"
            :rules="nameRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Location in Konkani"
            class="mb-4 ml-2"
            v-model="locationKn"
            :rules="nameRules"
          ></va-input>
        </div>
      </div>

      <va-select
        class="mb-4"
        :options="newsTypes"
        label="News Type"
        v-model="selectedNewsType"
        :items="newsTypes"
        :rules="[(v) => !!v || 'News Type is required']"
      />

      <!-- <div class="flex row">
        <div class="flex"> -->
      <va-date-input v-model="createdDate" class="mb-4" :format="formatDate">
      </va-date-input>
      <!-- </div>
      </div> -->
      <p
        class="mb-2 pl-2"
        :style="{ color: thumbnailImages.length == 0 ? '#FF0000' : '#111111' }"
      >
        News logo image:
      </p>
      <div style="display: flex; justify-content: flex-start" class="mb-4">
        <va-file-upload
          v-model="thumbnailImages"
          type="single"
          file-types="image/*"
        />
      </div>
    </div>
  </va-form>
  <va-button color="success" @click="submitForm"> Save </va-button>
  <va-button color="warning" class="ml-2" @click="clear">Clear</va-button>
</template>

<script>
import utility from "@/utility/utility";
import NewsDataService from "../services/news-data-service";
import NewsViewModel from "../view_models/news-view-model";
export default {
  props: {
    isVisible: Boolean,
  },
  emits: ["closeDialog"],
  data: () => ({
    thumbnailImages: [],
    validation: null,
    title: "",
    titleKn: "",
    description: "",
    descriptionKn: "",
    location: "",
    locationKn: "",
    createdDate: new Date(),
    selectedNewsType: "",
    newsTypes: NewsViewModel.getAllNewsType(),
    showLoading: false,
    nameRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length > 1) || "Name must be atleast 1 characters long",
    ],
  }),

  methods: {
    formatDate(date) {
      return `${utility.monthNames[date.getMonth()]} - ${date.getFullYear()}`;
    },
    dismissDialog() {
      this.$emit("closeDialog");
    },
    submitForm() {
      this.$refs.newsForm.validate();
      console.log("validation is ", this.validation);
      if (
        this.validation != null &&
        this.validation != false &&
        this.thumbnailImages.length > 0
      ) {
        this.showLoading = true;
        this.uploadNews();
      } else {
        this.$vaToast.init({
          message: "Please fill the required fields.",
          color: "danger",
        });
      }
    },
    validate() {
      // if (
      //   this.title.length > 0 &&
      //   this.title.length > 0 &&
      //   this.description.length > 0 &&
      //   this.description.length > 0 &&
      //   this.thumbnailImages.length > 0
      // ) {
      //   this.valid = true;
      // }
    },
    clear() {
      this.resetAllData();
    },
    resetAllData() {
      this.thumbnailImages = [];
      this.validation = null;
      this.title = "";
      this.titleKn = "";
      this.description = "";
      this.descriptionKn = "";
      this.location = "";
      this.locationKn = "";
      this.createdDate = new Date();
      this.showLoading = false;
      this.selectedNewsType = "";
      // this.$refs.newsForm.reset();
      // this.$refs.newsForm.resetValidation();
    },
    async uploadNews() {
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("titleKn", this.titleKn);
      formData.append("description", this.description);
      formData.append("descriptionKn", this.descriptionKn);
      formData.append("location", this.location);
      formData.append("locationKn", this.locationKn);
      formData.append("newsType", this.selectedNewsType);
      formData.append("createdDate", this.createdDate.getTime());
      if (this.thumbnailImages.length > 0) {
        formData.append("imageFile", this.thumbnailImages[0]);
      }

      try {
        const newsResponse = await NewsDataService.createNews(formData);
        if (newsResponse.status == 200 && newsResponse.data != null) {
          this.resetAllData();
          this.dismissDialog();
          this.$vaToast.init({
            message: "Successfuly created a news.",
            color: "success",
          });
        } else {
          this.showLoading = false;
          this.$vaToast.init({
            message: "Failed to upload news. Please try again.",
            color: "danger",
          });
        }
      } catch (error) {
        this.showLoading = false;
        console.log(error);
        this.$vaToast.init({
          message:
            "Failed to upload news. Please check all your fields and try again.",
          color: "danger",
        });
      }
    },
  },
};
</script>
