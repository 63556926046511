import httpClient from "../../../network/http_client";

class WardsDataService {

    getAllWards() {
        return httpClient.get('/wards');
    }
    createWard(formData) {
        return httpClient.post(
            "/wards",
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
    }
    updateWard(id, data) {
        return httpClient.put(`/wards/${id}`, data);
    }
    deleteWard(id) {
        const deleteUrl = `/wards/${id}`;
        return httpClient.delete(`/wards/${id}`);
    }
}

export default new WardsDataService();