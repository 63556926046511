  <template>
  <va-navbar color="#ffffff" class="mb-4 pa-0">
    <template #left>
      <va-navbar-item>
        <va-button
          icon="close"
          class="mr-2"
          @click="dismissDialog"
          flat
        ></va-button>
      </va-navbar-item>
    </template>
    <template #center>
      <va-navbar-item>Add Contact</va-navbar-item>
    </template>
    <template #right>
      <va-navbar-item>
        <div v-if="showLoading == false" class="mr-4" />

        <va-progress-circle
          v-else
          indeterminate
          size="3rem"
          :thickness="0.2"
          color="danger"
        ></va-progress-circle>
      </va-navbar-item>
    </template>
  </va-navbar>
  <va-form
    ref="contactForm"
    @validation="validation = $event"
    @submit.prevent="submitForm"
  >
    <div style="min-width: 500px">
      <div class="flex row">
        <div class="flex">
          <va-input
            label="Name"
            class="mb-4 mr-2"
            v-model="name"
            :rules="nameRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="Name in Konkani"
            class="mb-4 ml-2"
            v-model="nameKn"
            :rules="nameRules"
          ></va-input>
        </div>
      </div>
      <div class="flex row">
        <div class="flex">
          <va-input
            label="Mobile Number"
            class="mb-4 mr-2"
            v-model="mobileNumber"
            :rules="mobileNumberRules"
          ></va-input>
        </div>
        <div class="flex">
          <va-input
            label="LandLine Number"
            class="mb-4 ml-2"
            v-model="landlineNumber"
          ></va-input>
        </div>
      </div>
      <div class="mb-2"><label> Is Gurkar ? </label></div>

      <va-option-list
        type="radio"
        class="mb-4"
        :options="genderOptions"
        v-model="isGurkar"
      />
      <va-select
        class="mb-4"
        :options="wards"
        label="Ward"
        v-model="selectedWard"
        :items="wards"
        :rules="[(v) => !!v || 'Ward is required']"
      />
    </div>
  </va-form>
  <va-button color="success" @click="submitForm"> Save </va-button>
  <va-button color="warning" class="ml-2" @click="clear">Clear</va-button>
</template>

<script>
import ContactsDataService from "../services/contacts-data-service";
import WardViewModel from "../../wards/view_models//ward-view-model.js";
export default {
  props: {
    isVisible: Boolean,
  },
  emits: ["closeDialog"],
  data: () => ({
    validation: null,
    name: "",
    nameKn: "",
    mobileNumber: "",
    landlineNumber: "",
    isGurkar: "No",
    selectedWard: "",
    showLoading: false,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length > 3) || "Name must be atleast 3 characters long",
    ],
    mobileNumberRules: [
      (v) => !!v || "Mobile Number is required",
      (v) =>
        (v && v.length >= 10) ||
        "Mobile Number must be minimum of 10 characters long",
    ],
    landLineNumberRules: [
      (v) =>
        (v && v.length >= 10) ||
        "Landline Number must be minimum of 10 characters long",
    ],
    wards: WardViewModel.getAllWards(),
    genderOptions: ["Yes", "No"],
  }),

  methods: {
    dismissDialog() {
      this.$emit("closeDialog");
    },
    submitForm() {
      //this.validate();
      this.$refs.contactForm.validate();
      console.log("validation is ", this.validation);
      if (this.validation != null && this.validation != false) {
        this.showLoading = true;
        // setTimeout(() => {
        this.uploadContact();
        //}, 3000);
      } else {
        this.$vaToast.init({
          message: "Please fill the required fields.",
          color: "danger",
        });
      }
    },
    validate() {
      // if (
      //   this.name.length > 0 &&
      //   this.nameKn.length > 0 &&
      //   this.mobileNumber.length > 0 &&
      //   this.selectedWard.length > 0
      // ) {
      //   this.valid = true;
      // }
    },
    clear() {
      this.resetAllData();
    },
    resetAllData() {
      this.validation = null;
      this.name = "";
      this.nameKn = "";
      this.mobileNumber = "";
      this.landlineNumber = "";
      this.isGurkar = "No";
      this.selectedWard = "";
      this.showLoading = false;
      // this.$refs.contactForm.reset();
      // this.$refs.contactForm.resetValidation();
    },
    async uploadContact() {
      const gurkarBool = this.isGurkar == "Yes" ? true : false;
      const wardId = this.wards.indexOf(this.selectedWard);
      const contactsJson = {
        name: this.name,
        nameKn: this.nameKn,
        mobileNumber: this.mobileNumber,
        landlineNumber: this.landlineNumber,
        isGurkar: gurkarBool,
        landlineNumber: this.landlineNumber,
        wardId: `${wardId}`,
      };

      try {
        const contactsResponse = await ContactsDataService.createContact(
          contactsJson
        );
        if (contactsResponse.status == 200 && contactsResponse.data != null) {
          this.resetAllData();
          this.dismissDialog();
          this.$vaToast.init({
            message: "Successfuly created a contact.",
            color: "success",
          });
        } else {
          this.showLoading = false;

          this.$vaToast.init({
            message: "Failed to upload contact. Please try again.",
            color: "danger",
          });
        }
      } catch (error) {
        this.showLoading = false;
        console.log(error);
        this.$vaToast.init({
          message:
            "Failed to upload contact. Please check all your fields and try again.",
          color: "danger",
        });
      }
    },
  },
};
</script>
