<template>
  <base-header-view title="Institute"></base-header-view>
  <va-card class="ma-4">
    <va-card-content>
      <div class="mr-2 mt-2 d-flex flex-row-reverse">
        <va-button color="success" @click="toggleDialog(true)">
          Add Institute +
        </va-button>
      </div>
      <!-- Show Add Institute Page -->
      <va-modal
        v-model="isDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <div class="ma-0 pa-0"></div>
        <add-institute-page @closeDialog="toggleDialog(false)">
        </add-institute-page>
      </va-modal>

      <va-modal
        v-model="isEditInstituteDialogActive"
        hide-default-actions
        overlay-opacity="0.2"
        fixed-layout
        no-outside-dismiss
      >
        <edit-institute-page
          :selectedInstitute="selectedInstitute"
          @closeDialog="toggleEditInstituteDialog(false)"
        ></edit-institute-page>
      </va-modal>

      <va-modal
        v-model="showDeleteAlert"
        message="Do you want to delete this Institute ? "
        size="small"
        ok-text="Yes"
        cancel-text="No"
        @ok="deleteInstitute"
        @close="showDeleteAlert = false"
      />

      <va-data-table
        :items="institutes"
        :columns="headers"
        :filter="filter"
        :filter-method="customFilteringFn"
        @filtered="filteredCount = $event.items.length"
        striped
      >
        <template #cell(thumbImage)="{ rowIndex }">
          <va-image :src="getThumbImage(rowIndex)" />
        </template>
        <template #cell(createdDate)="{ rowIndex }">
          <p>{{ getFormationDate(rowIndex) }}</p>
        </template>
        <template #cell(actions)="{ rowIndex }">
          <va-button
            icon-right="create"
            class="mr-2"
            @click="showEditInstituteDialog(rowIndex)"
          >
            Edit
          </va-button>
          <va-button
            color="danger"
            icon-right="delete"
            @click="showDeleteInstituteDialog(rowIndex)"
          >
            Delete
          </va-button>
        </template>
        <template #bodyAppend>
          <tr>
            <td colspan="8" class="table-example--pagination">
              <va-pagination v-model="currentPage" input :pages="pages" />
            </td>
          </tr>
        </template>
      </va-data-table>
    </va-card-content>
  </va-card>
</template>
<script>
import utility from "@/utility/utility";
import InstituteDataService from "../services/institute-data-service";
import AddInstitutePage from "./AddInstitutePage.vue";
import EditInstitutePage from "./EditInstitutePage.vue";

export default {
  components: {
    AddInstitutePage,
    EditInstitutePage,
  },
  mounted() {
    this.fetchAllInstitute();
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      filtered: [],
      filter: "",
      useCustomFilteringFn: false,
      alertType: "error",
      isDialogActive: false,
      isEditInstituteDialogActive: false,
      showDeleteAlert: false,
      selectedInstitute: null,
      institutes: [],
      headers: [
        { key: "thumbImage", label: "Logo" },
        { key: "title", label: "Name", sortable: true },
        { key: "titleKn", label: "Name Kn", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "descriptionKn", label: "Description Kn", sortable: true },
        { key: "address", label: "Location", sortable: true },
        { key: "addressKn", label: "Location Kn", sortable: true },
        { key: "mobileNumber", label: "Mobile", sortable: true },
        { key: "landlineNumber", label: "Landline", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "createdDate", label: "Created Date", sortable: true },
        { key: "actions", width: 80 },
      ],
      filteredCount: 2,
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== 0
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length;
    },
    customFilteringFn() {
      return this.useCustomFilteringFn ? this.filterExact : undefined;
    },
  },

  methods: {
    getFormationDate(rowIndex) {
      const institute = this.institutes[rowIndex];
      const date = new Date(institute.createdDate);
      return `${utility.monthNames[date.getMonth()]} - ${date.getFullYear()}`;
    },
    getThumbImage(rowIndex) {
      const institute = this.institutes[rowIndex];
      return institute.thumbnailUrl;
    },
    filterExact(source) {
      if (this.filter === "") {
        return true;
      }

      return source?.toString?.() === this.filter;
    },
    toggleDialog(showDilaog) {
      this.isDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllInstitute();
      }
    },
    toggleEditInstituteDialog(showDilaog) {
      this.isEditInstituteDialogActive = showDilaog;
      if (showDilaog == false) {
        this.fetchAllInstitute();
      }
    },
    async fetchAllInstitute() {
      const instituteResponse = await InstituteDataService.getAllInstitutes();
      if (instituteResponse.status == 200 && instituteResponse.data != null) {
        console.log(instituteResponse.data);
        this.institutes = instituteResponse.data.institutes.map(
          this.getInstituteObjectForUI
        );
        this.filtered = this.institutes;
      } else {
        console.log("Failed to fetch Institute");
        this.$vaToast.init({
          message: "Failed to fetch Institute.",
          color: "danger",
        });
      }
    },
    async deleteInstituteFromServer() {
      const instituteResponse = await InstituteDataService.deleteInstitute(
        this.selectedInstitute.id
      );
      if (instituteResponse.status == 200) {
        this.fetchAllInstitute();
        this.$vaToast.init({
          message: "Successfuly deleted Institute.",
          color: "success",
        });
      } else {
        this.$vaToast.init({
          message: "Failed to delete Institute.",
          color: "danger",
        });
      }
    },
    getInstituteObjectForUI(instituteData) {
      return {
        id: instituteData.id ?? "",
        title: instituteData.title ?? "",
        titleKn: instituteData.titleKn ?? "",
        description: instituteData.description ?? "",
        descriptionKn: instituteData.descriptionKn ?? "",
        address: instituteData.address ?? "",
        addressKn: instituteData.addressKn ?? "",
        mobileNumber: instituteData.mobileNumber ?? "",
        landlineNumber: instituteData.landlineNumber ?? "",
        email: instituteData.email ?? "",
        createdDate: instituteData.createdDate ?? "",
        thumbnailUrl: instituteData.thumbnailUrl ?? "",
      };
    },

    showEditInstituteDialog(rowIndex) {
      const institute = this.institutes[rowIndex];
      this.selectedInstitute = institute;
      this.toggleEditInstituteDialog(true);
    },

    showDeleteInstituteDialog(rowIndex) {
      const institute = this.institutes[rowIndex];
      this.selectedInstitute = institute;
      this.showDeleteAlert = true;
    },

    deleteInstitute() {
      this.showDeleteAlert = false;
      console.log("ID ", this.selectedInstitute.id);
      this.deleteInstituteFromServer();
    },
  },
};
</script>
<style lang="scss" scoped>
.table-example--pagination {
  text-align: center;
  text-align: -webkit-center;
}
</style>