class GalleriesViewModel {

    getAllGalleries() {
        return [
            "association",
            "parish",
            "ward"
        ];
    }

    getGalleryTypeId(galleryName) {
        return this.getAllGalleries().indexOf(galleryName);
    }

    getGalleryTypeName(galleryId) {
        const index = parseInt(galleryId);

        return this.getAllGalleries()[index];
    }

}

export default new GalleriesViewModel()