import httpClient from "../../../network/http_client";

class PriestsDataService {

    getAllPriests() {
        return httpClient.get('/priests');
    }
    createPriest(formData) {
        return httpClient.post(
            "/priests",
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
    }
    updatePriest(id, data) {
        return httpClient.put(`/priests/${id}`, data);
    }
    deletePriest(id) {
        const deleteUrl = `/priests/${id}`;
        return httpClient.delete(`/priests/${id}`);
    }
}

export default new PriestsDataService();