import { createRouter, createWebHistory } from "vue-router";
import DashboardPage from './features/dashboard/views/DashboardPage.vue';
import ContactsPage from './features/contacts/views/ContactsPage.vue';
import TimingsPage from "./features/timings/views/TimingsPage.vue";
import AssociationsPage from "./features/associations/views/AssociationsPage.vue";
import NewsPage from "./features/news/views/NewsPage.vue";
import WardsPage from "./features/wards/views/WardsPage.vue";
import PriestsPage from "./features/priests/views/PriestsPage.vue";
import ChurchEventsPage from "./features/church_events/views//ChurchEventsPage.vue";
import GalleryPage from "./features/galleries/views/GalleryPage.vue";
import InstitutePage from "./features/institutes/views/InstitutesPage.vue";
import SignInPage from "./features/signIn/views/SigInPage.vue";
import BaseLayoutPage from "./features/base_layout/views/BaseLayoutView.vue";
import { authStore } from './store/auth_store.js';


const router = createRouter({
    history: createWebHistory(),
    // routes: [
    //     { path: '/', redirect: '/baseLayout' },
    //     { path: '/baseLayout', component: BaseLayoutPage, meta: { requiresAuth: true } },
    //     { path: '/dashboard', component: DashboardPage, meta: { requiresAuth: true } },
    //     { path: '/timings', component: TimingsPage, meta: { requiresAuth: true } },
    //     { path: '/associations', component: AssociationsPage, meta: { requiresAuth: true } },
    //     { path: '/contacts', component: ContactsPage, meta: { requiresAuth: true } },
    //     { path: '/galleries', component: GalleryPage, meta: { requiresAuth: true } },
    //     { path: '/institutes', component: InstitutePage, meta: { requiresAuth: true } },
    //     { path: '/news', component: NewsPage, meta: { requiresAuth: true } },
    //     { path: '/priests', component: PriestsPage, meta: { requiresAuth: true } },
    //     { path: '/wards', component: WardsPage, meta: { requiresAuth: true } },
    //     { path: '/churchEvents', component: ChurchEventsPage, meta: { requiresAuth: true } },
    //     { path: '/signIn', component: SignInPage, meta: { requiresAuth: false } },
    // ],
    routes: [
        { path: '/', redirect: '/signIn' },
        { path: '/signIn', component: SignInPage, meta: { requiresAuth: false } },
        {
            path: '/baseLayout', component: BaseLayoutPage, meta: { requiresAuth: true }, children: [
                { path: '/dashboard', component: DashboardPage, meta: { requiresAuth: true } },
                { path: '/timings', component: TimingsPage, meta: { requiresAuth: true } },
                { path: '/associations', component: AssociationsPage, meta: { requiresAuth: true } },
                { path: '/contacts', component: ContactsPage, meta: { requiresAuth: true } },
                { path: '/galleries', component: GalleryPage, meta: { requiresAuth: true } },
                { path: '/institutes', component: InstitutePage, meta: { requiresAuth: true } },
                { path: '/news', component: NewsPage, meta: { requiresAuth: true } },
                { path: '/priests', component: PriestsPage, meta: { requiresAuth: true } },
                { path: '/wards', component: WardsPage, meta: { requiresAuth: true } },
                { path: '/churchEvents', component: ChurchEventsPage, meta: { requiresAuth: true } },

            ]
        },

    ],
});


router.beforeEach(function (to, _, next) {

    const loginStore = authStore();
    console.log("isLoggedIn : ", loginStore.isLoggedIn);
    if (to.meta.requiresAuth && !loginStore.isLoggedIn) {
        next('/signIn');
    } else {
        next();
    }
});

export default router;


