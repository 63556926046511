import { defineStore } from "pinia";

export const authStore = defineStore('main', {

    state: () => ({
        userAccesstoken: "",
    }),
    actions: {
        updateToken(token) {
            //  console.log("Token is ", token);
            this.userAccesstoken = token;
            localStorage.setItem('userToken', this.userAccesstoken);
        },
        clearToken() {
            //console.log('clearToken called');
            localStorage.removeItem('userToken');
            //  console.log('Now ', localStorage.getItem('userToken'));
            this.updateToken("");
        }
    },
    getters: {
        isLoggedIn() {
            this.userAccesstoken = localStorage.getItem('userToken') == null ? "" : localStorage.getItem('userToken');
            //this.userAccesstoken != null || this.userAccesstoken != "";
            const isValueEmpty = this.userAccesstoken.length !== 0
            return isValueEmpty;
        }
    }
});